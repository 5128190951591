import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../../components/Layout/Layout';

import './ErrorPage.scss';
import { SnowplowPageViewTracker } from '../../components/Analytics/Snowplow/SnowplowPageViewTracker';

const CLOUDINARY_ROOT = '//d207ibygpg2z1x.cloudfront.net/image/upload/';

export const ERROR_MAP = {
    404: {
        image: `${CLOUDINARY_ROOT}v1573848275/SVGs/404girl.svg`,
        description: `Oops, that page doesn't exist. Please try again.`,
    },
    500: {
        image: `${CLOUDINARY_ROOT}v1574113981/SVGs/500girl.svg`,
        description: `Sorry, we're fixing an issue. Please try again later.`,
    },
};

export default class ErrorPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * enum of 404 or 500
         */
        errorStatus: PropTypes.oneOf([404, 500]),
    };

    componentDidMount() {
        if (this.props.cssLinkHref) {
            const headTag = document.querySelector('head');
            const linkTag = document.createElement('link');
            linkTag.rel = 'stylesheet';
            linkTag.type = 'text/css';
            linkTag.href = this.props.cssLinkHref;

            headTag.appendChild(linkTag);
        }
    }

    render() {
        const { errorStatus, PAGE_PROPS } = this.props;
        const descriptionCss = errorStatus === 404 ? 'description-left' : '';

        return (
            <div className={`page-ErrorPage ${descriptionCss}`}>
                <SnowplowPageViewTracker
                    pageSection="other"
                    pageType="other"
                />
                <Layout PAGE_PROPS={PAGE_PROPS} displayDesktopFooter={false}>
                    <img
                        className="error-image"
                        src={ERROR_MAP[errorStatus].image}
                        alt="error-image"
                    />
                    <p className="error-description">
                        {ERROR_MAP[errorStatus].description}
                    </p>
                </Layout>
            </div>
        );
    }
}

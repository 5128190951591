// Unique code to get top level Page Component and Page SCSS
import React from 'react';
// All boilerplate cut/paste stuff
import ReactDOM from 'react-dom/client';

import { Provider as PAGE_CONTEXT } from '../../contexts/PageContext';
// The only thing different is the from part should match your component
import PAGE_COMPONENT from './ErrorPage';

// eslint-disable-next-line no-undef
const props = globalPageProps || {};

ReactDOM.hydrateRoot(
    document.getElementById('root'),
    <PAGE_CONTEXT PAGE_PROPS={props.PAGE_PROPS}>
        <PAGE_COMPONENT {...props} />
    </PAGE_CONTEXT>
);

// TO USE THIS COMPONENT IN THE BACKEND:

/**
 *  To render the component:
 *
 *  req.reactRender = {
 *      componentPath: 'ErrorPage/ErrorPage,
 *      props: {},
 *      metaTagData:{}
 *  }
 *
 *  next();
 *  return;
 * */
